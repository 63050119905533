import axios from "axios";
import { Toast } from "vant";
import { getLocal, clearLocal } from "@/common/js/utils";
import router from "@/router";
import configSet from "./config";

const request = axios.create({
  baseURL: configSet.domain.main,
});

request.defaults.withCredentials = true;
request.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
request.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

request.interceptors.request.use(
  (config) => {
    if (getLocal("ba-user-token")) {
      config.headers["ba-user-token"] = getLocal("ba-user-token");
    }

    // Toast.loading({
    //   duration: 1000,
    //   message: "加载中",
    //   forbidClick: true,
    // });

    if (config.params) {
      if (
        typeof config.params.notShowToast == "undefined" ||
        !config.params.notShowToast
      ) {
        Toast.loading({
          duration: 1000,
          message: "加载中",
          forbidClick: true,
        });
      }
    } else {
      Toast.loading({
        duration: 1000,
        message: "加载中",
        forbidClick: true,
      });
    }

    // if (
    //   typeof config.params.notShowToast == "undefined" ||
    //   typeof config.data == "undefined" ||
    //   !config.params.notShowToast
    // ) {
    //   Toast.loading({
    //     duration: 1000,
    //     message: "加载中",
    //     forbidClick: true,
    //   });
    // }

    if (configSet.isDebug) {
      console.log("----------发起请求----------");
      console.log(config);
    }

    return config;
  },
  (error) => {
    Toast.fail("请求数据异常！");

    if (configSet.isDebug) {
      console.log("----------发起请求----------");
      console.log(error);
      console.log("----------发起错误----------");
    }

    Promise.reject(error);
  }
);

request.interceptors.response.use(
  (res) => {
    // Toast.clear()

    if (configSet.isDebug) {
      console.log(res);
      console.log("----------请求结束----------");
    }

    if (res.status == 200) {
      // const apiHeaders = res.headers
      const apiRes = res.data;
      if (apiRes.code === 1) {
        // if(apiHeaders.authorization){
        // 	setLocal('Authorization', apiHeaders.authorization)
        // }

        return apiRes;
      } else {
        // if(apiRes.code !== 40044){ //过滤图片获取错误提示
        Toast.fail(apiRes.msg);
        // }

        if (apiRes.code == 409) {
          // TOKEN EXPIRE
          clearLocal();
          router.push({ name: "login" });
        }

        return false;
      }
    } else {
      Toast.fail("服务器异常！");

      return false;
    }
    // if (typeof res.data !== 'object') {
    //   Toast.fail('服务端异常！')
    //   return Promise.reject(res)
    // }
    // if (res.data.resultCode != 200) {
    //   if (res.data.message) Toast.fail(res.data.message)
    //   if (res.data.resultCode == 416) {
    //     router.push({ path: '/login' })
    //   }
    //   if (res.data.data && window.location.hash == '#/login') {
    //     setLocal('Authorization', res.data.data)
    //     axios.defaults.headers['Authorization'] = res.data.data
    //   }
    //   return Promise.reject(res.data)
    // }

    // return res.data
  },
  (error) => {
    Toast.clear();
    Toast.fail("服务器异常！");
    console.log("error");

    if (configSet.isDebug) {
      console.log(error);
      console.log("----------返回错误----------");
    }

    return false;

    // const apiRes = error.response.data || {
    // 	code: 40000
    // }
    // if(apiRes.code == 40305){ // TOKEN ERROR
    // 	clearLocal()
    // 	router.push({ name: "login" })
    // }
  }
);

export default request;
