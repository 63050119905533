<template>
  <div class="page">
    <div class="bar">
      <van-nav-bar
        title="标题"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
      />
    </div>

    <div class="pageContent">
      <div class="basis">
        <h3>基础信息</h3>
        <table border="0">
          <tr>
            <td>学案名称：</td>
            <td>学案名称学案名称</td>
          </tr>
          <tr>
            <td>学科：</td>
            <td>学案名称学案名称</td>
          </tr>
          <tr>
            <td>教材类型：</td>
            <td>学案名称学案名称</td>
          </tr>
          <tr>
            <td>教材：</td>
            <td>学案名称学案名称</td>
          </tr>
          <tr>
            <td>目录：</td>
            <td>学案名称学案名称</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { apiGetCasesResource } from "@/service/teacher.js";

const getCaseInfo = async () => {
  let res = await apiGetCasesResource();
};

const onClickLeft = () => history.back();
</script>

<style lang="less" scoped>
.bar {
  width: 100%;
  position: sticky;
  top: 0;
}
.pageContent {
  width: 80%;
  margin: 10px auto;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  .basis {
    h3 {
      position: relative;
      padding-left: 10px;
      margin: 0;
      &::before {
        content: "";
        height: 13px;
        width: 5px;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 2px;
        background-color: rgb(149, 242, 2);
      }
    }
    table {
      margin-top: 10px;
      font-size: 12px;
    }
  }
}

@media screen and (orientation: portrait) and (max-device-width: 420px) {
}
</style>
